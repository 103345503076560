// src/components/AboutPage.js
import React from 'react';

function AboutPage() {
  return (
    <div>
      <h1>About Us</h1>
      {/* Add content for the about page */}
    </div>
  );
}

export default AboutPage;
