// src/components/ContactPage.js
import React from 'react';

function ContactPage() {
  return (
    <div>
      <h1>Contact Us</h1>
      {/* Add content for the contact page */}
    </div>
  );
}

export default ContactPage;
