// src/components/HomePage.js
import React from 'react';

function HomePage() {
  return (
    <div>
      <h1>Welcome to the Home Page</h1>
      {/* Add content for the home page */}
    </div>
  );
}

export default HomePage;
